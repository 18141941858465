import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import Summary from "../../components/Epharmacy/Summary"

export default ({ location }) => (
  <Layout
    title="Order Summary"
    subtitle={
      <span>
        Please review the details of your order, mobile number, and delivery
        address. <br className="is-hidden-mobile" /> We will verify your order
        via SMS through the mobile number indicated.
      </span>
    }
    seoTitle="Order Summary"
    process="epharmacy"
    display={{ footer: false, helpCenterBanner: false }}
    isPrivate
    isPatient
  >
    <Container isCentered desktop={6} fullhd={6}>
      <Summary
        backRoute="/epharmacy/shipping-and-payment"
        route={location?.pathname}
        baseRoute="/epharmacy"
      />
    </Container>
  </Layout>
)
