import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome, faBuilding, faIdCard } from "@fortawesome/free-solid-svg-icons"

import ErrorMessage from "../Elements/ErrorMessage"

import requiredFields from "./utils/requiredFields.json"
import { formatPrice } from "../Epharmacy/services/computations"

const hasIncompleteValues = (data, flow) => {
  for (let i = 0; i < requiredFields[flow].length; i++) {
    let keys = requiredFields[flow][i].split(".")
    let finalValue = keys.reduce((object, key) => object[key], data)

    if (!finalValue) return true
  }
  return false
}

const PatientDetailsSummary = ({ data, deliveryAddress, flow }) => {
  if (hasIncompleteValues(data, flow))
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  let {
    firstName,
    lastName,
    mobileNumber,
    emailAddress,
    scpwdIDNumber,
    courierOption,
    paymentOption,
    preferredCourierOption,
    isSameDay,
    preferredDeliveryDate,
    preferredDeliveryTime,
    doctorName,
    hospitalName,
    otherHospital,
    authorizedPerson,
    changeFor,
    patientCode,
    rating,
    feedback,
  } = data

  const addressIcon =
    deliveryAddress?.addressType === "Home" ? faHome : faBuilding
  const COD_STRING = "Cash on Delivery"
  const IS_SAME_DAY = isSameDay === "Yes"

  return (
    <div className="px-1">
      {firstName && (
        <p>
          <span className="has-text-weight-bold">
            {firstName} {lastName}
          </span>
        </p>
      )}
      {mobileNumber && (
        <p>
          {mobileNumber} | {emailAddress}
        </p>
      )}
      {deliveryAddress?.streetAddress && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={addressIcon} />
          </span>{" "}
          {deliveryAddress.streetAddress}, {deliveryAddress.city.value},{" "}
          {deliveryAddress.province.value}
        </p>
      )}
      {deliveryAddress.notes && (
        <p className="mb-1-mobile">
          Delivery Notes:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {deliveryAddress.notes}
          </span>
        </p>
      )}
      {patientCode && (
        <p className="mb-1-mobile">
          CarePlus Patient Code:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {patientCode}
          </span>
        </p>
      )}
      {!IS_SAME_DAY && preferredDeliveryDate?.month?.value && (
        <p className="mt-1 mb-1-mobile">
          Preferred Delivery Date:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {preferredDeliveryDate.month.value}{" "}
            {preferredDeliveryDate.date.value},{" "}
            {preferredDeliveryDate.year?.value || preferredDeliveryDate.year}
          </span>
        </p>
      )}
      {!IS_SAME_DAY && preferredDeliveryTime && (
        <p className="mb-1-mobile">
          Preferred Delivery Time:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {preferredDeliveryTime}
          </span>
        </p>
      )}
      {scpwdIDNumber && (
        <p>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faIdCard} />
          </span>{" "}
          SCPWD ID #{scpwdIDNumber}
        </p>
      )}
      {(preferredCourierOption || (paymentOption && courierOption)) && (
        <p className="mb-1-mobile">
          Shipping Option:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {courierOption}
            {paymentOption && courierOption && " | "}
            {paymentOption}
          </span>
        </p>
      )}
      {changeFor && paymentOption === COD_STRING && (
        <p>
          Change for:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />P
            {formatPrice({ priceString: parseFloat(changeFor).toFixed(2) })}
          </span>
        </p>
      )}
      {doctorName && (
        <p className="mt-1 mb-1-mobile">
          Prescribing Doctor:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {doctorName}
          </span>
        </p>
      )}
      {hospitalName && (
        <p>
          Hospital Name:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {hospitalName?.value === "Other"
              ? otherHospital
              : hospitalName?.value}
          </span>
        </p>
      )}
      {authorizedPerson?.fullName && (
        <p className="mt-1 mb-1-mobile">
          Authorized Person to Receive
          <div className="has-text-weight-bold">
            {authorizedPerson?.fullName}
          </div>
          <div>{authorizedPerson?.contactNumber || "N/A"}</div>
        </p>
      )}
      {rating && rating.value && (
        <p className="mt-1 mb-1-mobile">
          Rating:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {rating.value}
          </span>
        </p>
      )}
      {feedback && (
        <p className="mb-1-mobile">
          Feedback:{" "}
          <span className="has-text-weight-bold">
            <br className="is-hidden-desktop is-hidden-tablet" />
            {feedback}
          </span>
        </p>
      )}
    </div>
  )
}

export default PatientDetailsSummary
