import React from "react"
import Section from "../Elements/Section"
import EditDetailsButton from "./EditDetailsButton"

const OrderNotesSummary = ({ notes, route }) => {
  return (
    <Section
      title="Order Notes"
      addOns={{ right: <EditDetailsButton route={route} /> }}
    >
      {notes?.split("\n").map((orderNote) => (
        <p className="px-1 is-size-6">{orderNote}</p>
      ))}
    </Section>
  )
}

export default OrderNotesSummary
