import React from "react"

import Message from "../Elements/Message"
import { deliveryChannelUpdates } from "./utils/deliveryChannelUpdates"

import {
  accumulatePrice,
  formatPrice,
} from "../Epharmacy/services/computations"
import OrderTotal from "./MedsOrderedSummary/OrderTotal"

const OrderSubtotalSummary = ({ medicines, courier, deliveryFee }) => {
  const totalPrice = formatPrice({
    priceString: (accumulatePrice(medicines) + deliveryFee).toFixed(2),
  })

  const channelUpdates = deliveryChannelUpdates.find(
    (channel) => channel.courierOption === courier
  )

  return (
    <div className="px-1 mb-3">
      {channelUpdates && (
        <Message color="warning" className="mt-1">
          <p className="has-text-black is-size-6">{channelUpdates.message}</p>
        </Message>
      )}
      <OrderTotal totalPrice={totalPrice} />
    </div>
  )
}

export default OrderSubtotalSummary
