import React from "react"
import classNames from "classnames"

const OrderTotal = ({ totalPrice }) => (
  <div className={classNames("is-size-5 has-text-grey has-text-left mt-1")}>
    Order Total:{" "}
    <span className="ml-1 has-text-black has-text-weight-bold">
      P {totalPrice}
    </span>
    <br />
    <span className="is-size-6">
      Order total and medicine assistance will be verified via SMS.
    </span>
  </div>
)

export default OrderTotal
