import React from "react"
import { Link } from "gatsby"

import Section from "../Elements/Section"
import MedicineInfoRow, {
  DeliveryFeeRow,
} from "./MedsOrderedSummary/MedicineInfoRow"
import EditDetailsButton from "./EditDetailsButton"
import ErrorMessage from "../Elements/ErrorMessage"

const MedsOrderedSummary = ({ medicines, route, deliveryFee }) => {
  return (
    <Section
      title={`Medicines Ordered (${medicines.length})`}
      addOns={{
        right: <EditDetailsButton route={route} />,
      }}
    >
      {medicines.length < 1 ? (
        <ErrorMessage
          message={
            <div className="has-text-centered">
              You have not added any medicines.
              <br />
              <Link to="/epharmacy/order">Add Medicines</Link>
            </div>
          }
        />
      ) : (
        <div className="px-1">
          {medicines.map((item) => (
            <MedicineInfoRow medicine={item} />
          ))}
          {deliveryFee > 0 && (
            <DeliveryFeeRow deliveryFee={deliveryFee.toFixed(2)} />
          )}
        </div>
      )}
    </Section>
  )
}

export default MedsOrderedSummary
